import { Injectable } from "@angular/core";
import moment from "moment";
import { Geolocation, Geoposition } from "@ionic-native/geolocation/ngx";
import { Subscription } from "rxjs";
import { Storage } from "@ionic/storage";

@Injectable({
  providedIn: "root",
})
export class DistanceUtilsService {
  userLocation: any;
  constructor(private geolocation: Geolocation, private storage: Storage) {}

  async calculateDistance(
    lat1: number,
    lon1: number,
    unit: "miles" | "kilometers" | "meters" = "kilometers"
  ) {
    try {
      if (!this.userLocation) this.userLocation = await this.getCurrentLocation();
      // this.userLocation = { latitude: 41.1082021, longitude: -75.4554563 };

      let radius: number;

      // Define el radio según la unidad de medida
      if (unit === "miles") {
        radius = 3958.8; // Earth's radius in miles
      } else if (unit === "kilometers") {
        radius = 6371; // Earth's radius in kilometers
      } else if (unit === "meters") {
        radius = 6371000; // Earth's radius in meters
      } else {
        throw new Error("Unidad de medida no válida");
      }

      const toRadians = (degrees: number) => {
        return degrees * (Math.PI / 180);
      };

      const dLat = toRadians(this.userLocation.latitude - lat1);
      const dLon = toRadians(this.userLocation.longitude - lon1);

      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRadians(lat1)) *
          Math.cos(toRadians(this.userLocation.longitude)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);

      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      const distance = radius * c;

      return parseFloat(distance.toFixed(2));
    } catch (error) {
      console.log(error);
    }
  }


  async getCurrentLocation() {
    console.log("getCurrentLocation", moment().toDate());
    try {
      const location = await this.geolocation.getCurrentPosition({
        enableHighAccuracy: true,
        timeout: 9000,
      });

      console.log(location)

      if (!location.coords) {
        const lastLocation = await this.storage.get("lastLocation");
        this.userLocation = lastLocation;
        return this.userLocation;
      } else {
        this.userLocation = {
          latitude: location.coords.latitude,
          longitude: location.coords.longitude,
        };
        await this.storage.set("lastLocation", this.userLocation);
        return this.userLocation;
      }
    } catch (error) {
      console.error("Error getting location", error);

      const lastLocation = await this.storage.get("lastLocation");
      if (lastLocation) {
        this.userLocation = lastLocation;
        return this.userLocation;
      }

      throw new Error("No location data available");
    }
  }

}
