export const settings = {
  languages: [
    { label: "English", value: 'en' },
    { label: 'Español', value: 'es' },
    { label: 'Français', value: 'fr' },
    { label: 'Deutsch', value: 'de' },
    { label: '中文', value: 'sc' },
    { label: 'हिन्दी', value: 'hi' }
  ],
  site: {
    name: 'Poconos',
    address: '1004 W. Main St. Stroudsburg, PA 18360',
    phone: '570-844-2001',
    map: 'https://maps.app.goo.gl/p69viVVSijP7yrby6',
    latitude: 40.9839353,
    longitude: -75.1999255,
    website:'https://www.poconomountains.com/',
    privacyLink:'https://www.poconomountains.com/privacy-policy/',
    twitter:'https://twitter.com/PoconoTourism',
    facebook: 'https://www.facebook.com/PoconoTourism',
    pinterest:'https://www.pinterest.com/poconotourism/',
    instagram:'https://instagram.com/poconotourism/',
    youtube:'',
    splashImage: '../themes/Poconos/assets/splashscreens/bg_img.png',
    homeEvents: false
  },
  debug: true

};
