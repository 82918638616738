import { Component, OnInit } from '@angular/core';

import { CMSDataControllerService } from '@app/core/data/controller/cmsdata-controller.service';
import { CMSEvent } from '@app/core/data/CMSEvent';
import { environment } from '@env/environment';
// import moment from 'moment';
import { SubCategory } from '@app/core/data/SubCategory';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
declare function require(name:string);

import { Storage } from '@ionic/storage';
import { LoadingService } from '../services/loading.service';
import moment from "moment-timezone";
@Component({
  selector: 'app-featured-events-row',
  templateUrl: require('template/' + environment.template + '/html/featured-events-row.component.html'),
  styles: [require('template/' + environment.template + '/styles/featured-events-row.component.scss')]
})
export class FeaturedEventsRowComponent implements OnInit {

  public environment = environment;
  events!: CMSEvent[];
  listings!: SubCategory[];
  filteredListings!: Map<string,boolean>;
  visibleListings!: SubCategory[];

  language = this.translate.currentLang;
  constructor(
    private router: Router,
    public storage: Storage,
    private loading: LoadingService,
    public translate: TranslateService,
    private dataController: CMSDataControllerService,
    ) {
      storage.get('language').then(lang => {
      console.log(lang)
      translate.setDefaultLang(lang || 'en')
    })
  }

  async ngOnInit() {
    await this.loading.presentLoading();
    // let map = new Map();
    // this.dataController.getEvents2({ domainId: environment.cms.domainId, dataSyndication: false }).subscribe(async list => {
      // filter events out so only not expired events will show up
      let list = await this.fetchEvents();
      await this.loading.dismiss();
      let events = this.filterAndSortEventsByCurrentDate(list);
      // console.log(events);
      this.events = this.getValidEventsWithNextOccurrence(events, list);
      // if (!categoryIdOrsubCategoryId) this.events = this.events.filter(event => this.categoriesAllowed.includes(event.CategoryID));
      console.log(list);
      // Obtener la fecha actual en formato UTC
      const currentDate = moment().utc();

      // Filtrar los eventos que son igual al día actual o en el futuro
      this.events = list.filter((event) => {
        const eventStartDate = moment.tz(event.StartDate, "UTC");
        return eventStartDate.isSameOrAfter(currentDate, "day"); // Comparar solo el día
      });

      // Ordenar los eventos por fecha de inicio
      this.events.sort((a, b) => {
        const aStartDate = moment.tz(a.StartDate, "UTC").valueOf();
        const bStartDate = moment.tz(b.StartDate, "UTC").valueOf();
        return aStartDate - bStartDate;
      });

      // // Inicializar otras variables
      // this.visibleEvents = this.events;
      console.log(this.events)
      // this.filteredEvents = new Map<string, boolean>();

      // Filtrar eventos únicos y cargarlos en un mapa
      let map = new Map();
      this.events = this.events.splice(0,8).filter((event) => {
        if (!map.has(event.EventId)) {
          map.set(event.EventId, "");
          return true;
        }
      });
      this.loading.dismiss();
      console.log(this.events)
    // });
  }
  private async fetchEvents() {
    const eventsString = await this.storage.get("events");
    if (eventsString) {
      return JSON.parse(eventsString);
    } else {
      const list = await this.dataController.getEvents2({ domainId: environment.cms.domainId, dataSyndication: true }).toPromise();
      return list;
    }
  }

  filterAndSortEventsByCurrentDate(
    events: CMSEvent[]
  ): CMSEvent[] {
    const today = moment().startOf("day");

    // Filter events with dates equal to or after the current day
    const filteredEvents = events.filter((event) => {
      const eventDate = moment.tz(event.StartDate, "UTC").startOf("day");
      return eventDate.isSameOrAfter(today, "day");
    });

    return filteredEvents;
  }

  getValidEventsWithNextOccurrence(
    firstResponse: any[],
    secondData: CMSEvent[]
  ): CMSEvent[] {
    const validEvents: CMSEvent[] = [];

    // Iterate through the response of the first function
    for (const firstEvent of firstResponse) {
      // Find the corresponding event in the second data
      const matchingEvent = secondData.find(
        (event) => event.EventID === firstEvent.EventID
      );
      // console.log(matchingEvent)

      // Validate if a corresponding event is found
      if (matchingEvent) {
        // Check if the event has an EndDate
        if (matchingEvent.EndDate) {
          // Validate if the EventDate is within the range StartDate - EndDate
          if (
            moment(firstEvent.StartDate).isBetween(
              matchingEvent.StartDate,
              matchingEvent.EndDate,
              "day",
              "[]"
            )
          ) {
            validEvents.push({
              ...matchingEvent,
              StartDate: firstEvent.StartDate,
            });
          }
        } else {
          // The event does not have an EndDate, find the next occurrence from the current day
          const nextOccurrence = moment(matchingEvent.StartDate)
            .startOf("day")
            .add(1, "week"); // You can adjust the logic as needed

          // Validate if the StartDate is within the range StartDate - Next occurrence
          if (
            moment(firstEvent.StartDate).isBetween(
              matchingEvent.StartDate,
              nextOccurrence,
              "day",
              "[]"
            )
          ) {
            validEvents.push({
              ...matchingEvent,
              StartDate: nextOccurrence.toISOString(),
            });
          }
        }
      }
    }

    return validEvents;
  }
  format(date: string, format: string): string {
    return moment(date).format(format);
  }

  onClick(event){
    console.log(event);
    // this.router.navigate(['/tabs/category'], { queryParams: { t: event.SubCategoryName, subCategoryId: event.SubCategoryID } });
    this.router.navigate([`tabs/events/${event.EventId}`])
  }

  inRange(rangeStart: Date, rangeEnd?: Date, eventStartDate?: string, eventEndDate?: string): boolean {
    if (!eventStartDate || !eventEndDate) {
      // an event it's considered candidate to check his range if has a non-null start & end dates
      return false;
    }
    if(new Date(eventStartDate).getTime() >= rangeStart.getTime()){
      return true
    }

    return false;
  }

}
