import { DistanceUtilsService } from '@app/shared/services/distance-utils.service';
import { Component } from "@angular/core";
import { Platform, ToastController } from "@ionic/angular";
import { Router } from "@angular/router";
import { ServiceWorkerUpdateService } from "./core/services/service-worker-update.service";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { Storage } from "@ionic/storage";
import { NetworkService } from "./services/network.service";
import { TokenControllerService } from "./messages-sdk/services/token-controller.service";
import { TranslateService } from "@ngx-translate/core";
import { CodebaseTranslateService } from "./services/codebase-translate.service";
import { ChannelsClientService } from "./messages-sdk/services/channels-client.service";
import { Push, PushObject, PushOptions } from "@ionic-native/push/ngx";
import { environment } from "@env/environment";
import { WeatherApiService } from "./shared/weather-api/weather-api.service";
import moment from "moment";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject, observable, Observable } from "rxjs";
import { StorageService } from "./services/storage.service";
import { CMSDataControllerService } from "./core/data/controller/cmsdata-controller.service";
import { SocialWallClientService } from './shared/social-wall-client/social-wall-client.service';

declare function require(name: string);

@Component({
  selector: "app-root",
  templateUrl: require("template/" +
    environment.template +
    "/html/app.component.html"),
})
export class AppComponent {
  public showSplash: boolean = true;
  public splashImage: string;
  weather: any;
  public mainImage: any;
  public IBURL :SafeResourceUrl;
  public markers: any[] = [];
  public environment = environment;
  bodyStyles = document.body.style;

  public iconFiles = {
    159: "pin_artsculture.png",
    1027: "pin_dine.svg",
    1028: "pin_todo.svg",
    4: "pin_outdoor.png",
    1032: "pin_stay.svg",
    6: "pin_shop.png",
    5: "pin_well.png",
  };
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private update: ServiceWorkerUpdateService,
    private router: Router,
    private dataController: CMSDataControllerService,
    private storage: Storage,
    private push: Push,
    private distanceUtilsService: DistanceUtilsService,
    private toastController: ToastController,
    private tokenController: TokenControllerService,
    private networkService: NetworkService,
    private translate: TranslateService,
    private channelService: ChannelsClientService,
    private weatherApiService: WeatherApiService,
    private storageService: StorageService,
    private socialWallClient: SocialWallClientService,
    private codebaseTranslateService: CodebaseTranslateService,
    private sanitizer: DomSanitizer

  ) {
    this.initializeApp();
    // storage.get('language').then(lang => {
    // })
  }

  ngOnInit() {
    this.storageService.watchItinerarySrc().subscribe((newValue) => {
      if (newValue) {
        console.log('itinerarySrc actualizado:', newValue);
        this.IBURL = this.sanitizer.bypassSecurityTrustResourceUrl(newValue); // Realiza la lógica necesaria
      }
    });
  }

  async initializeApp() {
    this.mainImage = `../../../themes/${environment.theme.name}/assets/splash/background_splash.png`;
this.bodyStyles.setProperty(
  "--image",
  `url("/themes/${environment.theme.name}/assets/splash/background_splash.png")`
);
this.bodyStyles.setProperty(
  "--logo",
  `url("/themes/${environment.theme.name}/assets/login/logo.png")`
);
    let promises = [];
    const coordinates = await this.storage.get("lastLocation")
    promises.push(this.dataController.getListings2({
      domainId: environment.cms.domainId,
      dataSyndication: false,
    }).toPromise())
    promises.push(this.dataController.getEvents2({ domainId: environment.cms.domainId, dataSyndication: true }).toPromise())
    promises.push(this.socialWallClient.getMedias({client_secret: environment.socialWall.client_secret, client_id: environment.socialWall.client_id}, 0, 50))
    Promise.all(promises).then(async resp => {
      let listings = resp[0].map(item => ({
        ...item,
        latLng: new google.maps.LatLng(item.Latitude, item.Longitude)
      }));
      // Recorre los elementos y guarda sus detalles en IndexedDB
      for (const item of listings) {
        const markerData = {
          Latitude: item.Latitude,
          Longitude: item.Longitude,
          Icon: this.iconFiles[item.CategoryID] || "pin-todo.png",
          IconWidth: item.IconWidth || 22,
          IconHeight: item.IconHeight || 30,
          zIndex: item.zIndex || 1,
        };

        // Guarda cada marcador en IndexedDB
        this.markers.push(markerData);
      }
      await this.storage.set("markers", JSON.stringify(this.markers))
      await this.storage.set("listings", JSON.stringify(listings))
      await this.storage.set("events", JSON.stringify(resp[1]))
      await this.storage.set("medias", JSON.stringify(resp[2]))
    })
    let itineraryUrl = await this.storage.get("itinerarySrc")
    console.log(itineraryUrl, environment.ItineraryBuilder.base_url)
    this.distanceUtilsService.getCurrentLocation();
    if (itineraryUrl) {
      this.IBURL = this.sanitizer.bypassSecurityTrustResourceUrl(itineraryUrl)
    } else {
      this.IBURL = this.sanitizer.bypassSecurityTrustResourceUrl(environment.ItineraryBuilder.base_url)
    }
    this.weatherApiService
      .getWeather({ zipCode: environment.cms.postalCode })
      .then((weather) => {
        // this.weather = {
        //   icon: this.getIconWeather(weather.Forecast.Today.Description),
        //   current: `${weather.Forecast.Today.Current}°`,
        //   date: moment().format('dddd, MMMM D, YYYY')
        // }

        // this.codebaseTranslateService.languageChange("eng");
        var timer = 0;
        var interval = setInterval(() => {
          timer++;
          if (timer < 7) {
          } else {
            clearInterval(interval);
            this.showSplash = false;
            //console.log('Done');
          }
        }, 1000);
      })
      .catch((err) => {
        console.error(err);
        this.weather = ".";

        // this.codebaseTranslateService.languageChange("eng");
        var timer = 0;
        var interval = setInterval(() => {
          timer++;
          if (timer < 7) {
          } else {
            clearInterval(interval);
            this.showSplash = false;
            //console.log('Done');
          }
        }, 1000);
      });

      this.IBURL = environment.ItineraryBuilder.base_url;

    // this.splashImage = `../../../themes/${environment.theme.name}/assets/login/logIn_background.png`;
    // this.codebaseTranslateService.languageChange('eng')
    // this.codebaseTranslateService.initLanguage();
    if (this.networkService.getCurrentNetworkStatus() < 1) {
      const toast = await this.toastController.create({
        message: "This app is offline",
        duration: 2000,
        buttons: [
          {
            text: "Close",
            handler: () => {},
          },
        ],
      });
      // toast.present();
    }

    if (this.platform.is("android")) {
      var token = await this.storage.get("pushToken");
      if (!token) this.requestPermission();
    } else if (this.platform.is("ios")) {
      var token = await this.storage.get("pushToken");
      if (!token) this.requestNotificationPermission;
    }
  }

  getIconWeather(state: string): string {
    switch (state.toLowerCase()) {
        case 'clear':
        case 'sunny':
            return '☀️';
        case 'night clear':
            return '🌙';
        case 'partly cloudy':
        case 'scattered clouds':
            return '🌤️';
        case 'clouds':
        case 'broken clouds':
        case 'few clouds':
          return '☁️';
        case 'overcast':
        case 'overcast clouds':
            return '🌥️';
        case 'rain':
        case 'shower rain':
        case 'light rain':
            return '🌧️';
        case 'heavy rain':
            return '🌧️';
        case 'storm':
        case 'thunderstorm':
        case 'light thunderstorm':
        case 'heavy thunderstorm':
        case 'thunderstorm with rain':
            return '⛈️';
        case 'drizzle':
        case 'light drizzle':
            return '🌦️';
        case 'snow':
        case 'light snow':
        case 'heavy snow':
            return '❄️';
        case 'sleet':
            return '🌨️';
        case 'mist':
        case 'haze':
            return '🌫️';
        case 'wind':
        case 'breeze':
        case 'strong breeze':
        case 'gale':
        case 'strong gale':
            return '🌬️';
        case 'fog':
            return '🌁';
        case 'smoke':
            return '🔥';
        case 'sand':
        case 'dust':
            return '🌪️';
        case 'tornado':
            return '🌪️';
        case 'hurricane':
        case 'cyclone':
        case 'typhoon':
            return '🌀';
        case 'cold':
            return '🥶';
        case 'hot':
            return '🥵';
        default:
            return '🌤️';
    }
}


  requestNotificationPermission() {
    const options: PushOptions = {
      android: {},
      ios: {
        alert: "true",
        badge: true,
        sound: "false",
      },
    };

    const pushObject: PushObject = this.push.init(options);

    pushObject.on("registration").subscribe(async (registration: any) => {
      // console.log("Device registered", registration);
      await this.storage.set("pushToken", registration);
      await this.tokenController.registerDeviceTokenIos(registration);
      await this.channelService.postSubscribeToChannels(
        ["GlobalNotifications"],
        registration
      );
      // Aquí puedes enviar el token de registro a tu servidor para enviar notificaciones.
    });

    pushObject.on("notification").subscribe((notification: any) => {
      // console.log("Received a notification", notification);
      // Aquí puedes manejar la notificación cuando se recibe.
    });

    pushObject.on("error").subscribe((error: any) => {
      console.error("Error with Push plugin", error);
    });
  }

  requestPermission() {
    this.tokenController.requestPermission().subscribe(
      async (token) => {
        await this.storage.set("pushToken", token);
        await this.tokenController.registerDeviceToken(token);
        await this.channelService.postSubscribeToChannels(
          ["GlobalNotifications"],
          token
        );
      },
      async (err) => {
        console.log(err);
      }
    );
  }
}
